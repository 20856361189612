import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import FormHeader from 'widgets/FormHeader';
import lodash from 'lodash';
import StripeCheckoutForm from 'components/StripeCheckoutForm';

const styles = theme => ({
  creditCardDiv: {
    width: '100%',
    height: '100%'
  },
  mainDiv: {
    width: '100%',
    padding: "1em",
  },
});

function StripeCreditCardPage(props) {
  const paymentPartnerToken = lodash.get(props, 'location.state.paymentPartnerToken');
  const primaryColor = useSelector(state => state.primaryColor);
  const stripePromise = loadStripe("pk_live_51LIfdIFXgROqAOxvzDocIvG8KfcpEN6RDPzpijNgEKNLn2h9OvsikmwySF8twt7Pqz0HEs3xQOifUE9bHtN5ND15004hOXLIfn")

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#000000',
    },
  };

  if (primaryColor) {
    appearance.variables.colorPrimary = primaryColor;
  }

  const stripeOptions = {
    clientSecret: paymentPartnerToken,
    appearance,
  };

  return (
    <Box className={props.classes.mainDiv}>
      <FormHeader titleText="Complete Payment" history={props.history} hideBackButton={true} />
      <div className={props.classes.creditCardDiv}>
        <Elements options={stripeOptions} stripe={stripePromise}>
          <StripeCheckoutForm history={props.history} />
        </Elements>
      </div>
    </Box>
  );

}

export default withStyles(styles)(StripeCreditCardPage);
